export type MapStoreIconProps = {
  base64EncodedImage: string
  label: string
}

export const MapStoreIcon = ({ base64EncodedImage, label }: MapStoreIconProps) => (
  <svg width="200" height="100" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="circleClip">
        <circle cx="100" cy="20" r="20" />
      </clipPath>

      <filter id="f1" x="-50%" y="-50%" width="200%" height="200%">
        <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
        <feColorMatrix
          result="shadowMatrix"
          in="blurOut"
          type="matrix"
          values="0 0 0 0.3 0   0 0 0 0.3 0   0 0 0 0.3 0   0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrix" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>

    <image
      xlinkHref={base64EncodedImage}
      x="80"
      y="0"
      height="40"
      width="40"
      clipPath="url(#circleClip)"
      style={{ filter: "url(#f1)" }}
    />

    <rect x="68" y="35" rx="9" ry="9" width="64" height="17" fill="white" style={{ filter: "url(#f1)" }} />

    <text x="100" y="47" fontFamily="Arial" fontSize="11px" fill="black" textAnchor="middle">
      {label}
    </text>
  </svg>
)
