import { MapStoreIcon } from "@/components/MapStoreIcon"
import { ApiPin, ApiStoreSearchResult } from "@/types"
import { createElement } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { fetchImageAsBase64, getHighestPay } from "../util"
import tailwindTheme from "@/lib/frontend/tailwind.theme.json"
import { useMediaQuery as _useMediaQuery } from "@react-hookz/web"

export const convertSvgToImageElement = (svg: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image()

    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")!

    image.onload = () => {
      context.drawImage(image, 0, 0)
      resolve(image)
    }

    image.src = "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svg)
  })
}

interface FormatStoreMarkerLabelOptions {
  store: ApiStoreSearchResult
  includeSuffix?: boolean
  includeDecimal?: boolean
  includeEstimatedLabel?: boolean
}

export const getStoreMarkerLabel = ({
  store,
  includeSuffix = true,
  includeDecimal = true,
  includeEstimatedLabel = true,
}: FormatStoreMarkerLabelOptions): string => {
  const pay = getHighestPay(store.jobs)
  return formatStoreMarkerLabel({
    pay,
    payEstimated: store.jobs.some((job) => job.pay_estimated),
    includeSuffix,
    includeDecimal,
    includeEstimatedLabel,
  })
}

interface GetStoreMarkerLabelOptions {
  pay: number
  payEstimated?: boolean
  includeSuffix?: boolean
  includeDecimal?: boolean
  includeEstimatedLabel?: boolean
}

export const formatStoreMarkerLabel = ({
  pay,
  payEstimated = false,
  includeSuffix = true,
  includeDecimal = true,
  includeEstimatedLabel = true,
}: GetStoreMarkerLabelOptions): string => {
  let label = `$${pay.toFixed(includeDecimal ? 2 : 0).toLocaleString()}`

  if (includeSuffix) {
    label += "/hr"

    if (includeEstimatedLabel && payEstimated) {
      label += " (Est.)"
    }
  }

  return label
}

type GetPinMarkerLabelOptions = Omit<GetStoreMarkerLabelOptions, "store"> & {
  pin: ApiPin
}

export const getPinMarkerLabel = ({
  pin,
  includeSuffix = true,
  includeDecimal = true,
}: GetPinMarkerLabelOptions): string => {
  const pay = pin.job_pay_min

  if (!pay) return ""

  let label = `$${pay.toFixed(includeDecimal ? 2 : 0).toLocaleString()}`

  if (includeSuffix) {
    label += "/hr"

    // if (includeEstimatedLabel && store.jobs.some((job) => job.pay_estimated)) {
    //   label += " (Est.)"
    // }
  }

  return label
}

export const createStoreMapIconImage = async (store: ApiStoreSearchResult): Promise<HTMLImageElement> => {
  const label = getStoreMarkerLabel({ store })

  const base64EncodedImage = await fetchImageAsBase64(store.employer.logo_url)

  const svg = renderToStaticMarkup(
    createElement(MapStoreIcon, {
      base64EncodedImage,
      label,
    })
  )

  const image = await convertSvgToImageElement(svg)

  return image
}

export function useMediaQuery(query: string | ((config: typeof tailwindTheme) => string), defaultValue = false) {
  if (typeof query === "function") {
    query = query(tailwindTheme)
  }

  return _useMediaQuery(query, { initializeWithValue: defaultValue })
}

interface UseIsScreenSizeOptions {
  size: "xss" | "xs" | "sm" | "md" | "lg" | "xl"
  method?: "max" | "min"
  default?: boolean
}

export function useScreenIsSize({ size, method = "min", default: defaultValue = false }: UseIsScreenSizeOptions) {
  return useMediaQuery((theme) => `(${method}-width: ${theme.screens[size]})`, defaultValue)
}

export const useIsPhone = (defaultValue = false) => {
  return useScreenIsSize({ size: "xs", method: "max", default: defaultValue })
}

export const useIsSmall = (defaultValue = false) => {
  return useScreenIsSize({ size: "sm", method: "max", default: defaultValue })
}

export const useIsMedium = (defaultValue = false) => {
  return useScreenIsSize({ size: "md", method: "max", default: defaultValue })
}

export const useIsLarge = (defaultValue = false) => {
  return useScreenIsSize({ size: "lg", method: "max", default: defaultValue })
}

export function debounce(callback: (...args: any[]) => any, wait: number) {
  let timeoutId: number | null = null
  return (...args: any[]) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId)
    }

    timeoutId = window.setTimeout(() => {
      callback(...args)
    }, wait)
  }
}

/* eslint-disable @typescript-eslint/ban-types */
export function rateLimit(callback: Function, limit: number, interval: number): (...args: unknown[]) => void {
  let calls = 0
  const queue: { args: unknown[]; context: unknown }[] = []
  let intervalId: any

  const execute = () => {
    if (calls < limit && queue.length > 0) {
      const { args, context } = queue.shift()!
      callback.apply(context, args)
      calls++
      setTimeout(() => calls--, interval)
    }

    if (queue.length === 0) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  return function (...args: unknown[]) {
    // @ts-expect-error I don't feel like typing this today
    queue.push({ args, context: this as unknown })
    if (!intervalId) {
      intervalId = setInterval(execute, interval / limit)
    }
  }
}

// We must rate limit URL changes as exceeding 100 in 10 seconds can crash the
// tab. It's okay if we drop some of these changes because we store the state
// outside the URL as well.
//
// TODO Long term, we really need to be using router.push so that the
// application can refresh after code deploys for users currently on the
// site. Sadly, with our current query param based routing, modifying query
// parameters like `store` can cause modals to re-render after they have
// been displayed. This should be solveable by moving this query param
// updating out of an effect and directly into the action that is called,
// which cannot be a `useReducer` `dispatch` function.
// NOTE: 'slug' page if exists is populated by the `server` [...page] route
export const replaceURL = rateLimit((url: string) => window.history.replaceState({}, "", url), 50, 5)
