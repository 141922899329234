import type { StoreSearchMeta, GeoData, EnrichedStore } from "@/types"
import type { JobSearchResult } from "@/database-types"
import type { User, Session } from "@prisma/client"
import {
  IconBuildingWarehouse,
  IconBuildingStore,
  IconCar,
  IconToolsKitchen2,
  IconTrekking,
  IconExchange,
} from "@tabler/icons-react"

export const geoDenver: GeoData = {
  mocked: true,
  countryCode: "US",
  region: "Colorado",
  city: "Denver",
  zip: "",
  lat: 39.7392,
  lon: -104.9903,
  timezone: "America/Denver",
  query: "127.0.0.1",
}

export const geoBrooklyn: GeoData = {
  mocked: true,
  countryCode: "US",
  region: "New York",
  city: "Brooklyn",
  zip: "",
  lat: 40.665,
  lon: -73.9523,
  timezone: "America/New_York",
  query: "127.0.0.1",
}

export const promotedEmployers = ["cvs_health"]

export const category1Values = []

export const category2Values = [
  "Appliances",
  "Automotive",
  "Banking",
  "Beauty",
  "Caregiving",
  "Casual Dining",
  "Cleaning & Janitorial",
  "Convenience",
  "Customer Service",
  "Delivery",
  "Department Store",
  "Discount",
  "Driving",
  "Electronics",
  "Fast Casual",
  "Fast Food",
  "Furniture",
  "Gas",
  "Grocery Stores",
  "Hobbies",
  "Home Improvement",
  "HVAC",
  "Insurance",
  "Medical",
  "Merchandising and Marketing",
  "Military",
  "Pets",
  "Pizzerias",
  "Security",
  "Shopping",
  "Sporting Goods",
  "Substitute Teacher",
  "Teacher",
  "Trucking",
  "Tutoring",
  "Warehouse",
]

export const testStore: EnrichedStore = {
  id: "5t4bgYBNUFpb97WX1",
  title: "16TH & TREMONT",
  employer_id: "HMWxhQWUo83rQt",
  lat: 39.742811,
  lng: -104.9887542,
  exact_location: true,
  job_count: 4,
  highestPay: 25.59,
  jobIds: ["ZfJBN7oweU62LHD4", "ZfJBN7oweU62LHD13", "ZfJBN7oweU62LHD22", "ZfJBN7oweU62LHD31"],
  urgent: false,
  verified: true,
  source: "cloudcrawler",
  payEstimated: false,
  employer: {
    id: "HMWxhQWUo83rQt",
    title: "Starbucks Coffee Company",
    logo_url: "images/starbucks.jpg",
    categories: ["coffee-shop"],
  },
  jobs: [
    {
      id: "8b346863-d5da-44a1-bb6d-1fa3b91dcc6e",
      job_uuid: "597af5f9-c305-4731-8e84-489997993704",
      title: "Shift Supervisor",
      pay_max: 25.59,
      pay_min: 22.54,
      store_id: "5t4bgYBNUFpb97WX1",
      promoted: false,
      source: "cloudcrawler",
      verified: false,
      employer_categories: ["coffee-shop"],
      lat: 39.742811,
      lon: -104.9887542,
      pay_estimated: false,
      job_posted_at: "2024-04-30",
      urgent: true,
      job_categories: ["allowsPartTime"],
      occupations: [],
      feed_hash: "eyJzb3VyY2UiOiJhcHBjYXN0X2NwYSIsInJwYyI6MH0=",
      slug: "shift-supervisor-at-starbucks-coffee-company-597af5f9-c305-4731-8e84-489997993704",
    },
    {
      id: "ef1c3122-fceb-4533-86aa-9606e9e97343",
      job_uuid: "5507c384-deaf-4161-a588-d36c5a053f89",
      title: "Shift Supervisor",
      pay_max: 25.59,
      pay_min: 22.54,
      store_id: "5t4bgYBNUFpb97WX1",
      promoted: false,
      source: "cloudcrawler",
      verified: false,
      employer_categories: ["coffee-shop"],
      lat: 39.742811,
      lon: -104.9887542,
      pay_estimated: false,
      job_posted_at: "2024-04-30",
      urgent: false,
      job_categories: ["allowsPartTime"],
      occupations: [],
      feed_hash: "eyJzb3VyY2UiOiJhcHBjYXN0X2NwYSIsInJwYyI6MH0=",
      slug: "shift-supervisor-at-starbucks-coffee-company-5507c384-deaf-4161-a588-d36c5a053f89",
    },
    {
      id: "159bae2c-ae8a-4c4d-ac12-1bb340299882",
      job_uuid: "40187f21-abc3-49a2-a5e1-8f1be6b45e60",
      title: "Shift Supervisor",
      pay_max: 25.59,
      pay_min: 22.54,
      store_id: "5t4bgYBNUFpb97WX1",
      promoted: false,
      source: "cloudcrawler",
      verified: false,
      employer_categories: ["coffee-shop"],
      lat: 39.742811,
      lon: -104.9887542,
      pay_estimated: false,
      job_posted_at: "2024-04-30",
      urgent: false,
      job_categories: ["allowsPartTime"],
      occupations: [],
      feed_hash: "eyJzb3VyY2UiOiJhcHBjYXN0X2NwYSIsInJwYyI6MH0=",
      slug: "shift-supervisor-at-starbucks-coffee-company-40187f21-abc3-49a2-a5e1-8f1be6b45e60",
    },
    {
      id: "1fc8106a-6e8c-477e-9089-dbfe4d026648",
      job_uuid: "7be93fee-c3f3-4118-855a-4f0fb5f9ab85",
      title: "Shift Supervisor",
      pay_max: 25.59,
      pay_min: 22.54,
      store_id: "5t4bgYBNUFpb97WX1",
      promoted: false,
      source: "cloudcrawler",
      verified: false,
      employer_categories: ["coffee-shop"],
      lat: 39.742811,
      lon: -104.9887542,
      pay_estimated: false,
      job_posted_at: null,
      urgent: false,
      job_categories: ["allowsPartTime"],
      occupations: [],
      feed_hash: "eyJzb3VyY2UiOiJhcHBjYXN0X2NwYSIsInJwYyI6MH0=",
      slug: "shift-supervisor-at-starbucks-coffee-company-7be93fee-c3f3-4118-855a-4f0fb5f9ab85",
    },
  ],
}

export const initialStoreSearchMeta: StoreSearchMeta = {
  employers: [],
  categories: [],
}

export const testJob: JobSearchResult = {
  id: "ZfJBN7oweU62LHD31",
  job_uuid: "7be93fee-c3f3-4118-855a-4f0fb5f9ab85",
  title: "Shift Supervisor",
  url: "https://www.starbucks.com/careers/",
  pay_max: 25.59,
  pay_min: 22.54,
  store_id: "5t4bgYBNUFpb97WX1",
  promoted: false,
  source: "appcast_cpa",
  verified: false,
  posted_at: "2024-04-01",
  urgent: false,
  job_categories: ["barista"],
  employer_categories: ["coffee-shop"],
  real_lat: 39.742811,
  real_lng: -104.9887542,
  store_lat: 39.742811,
  store_lng: -104.9887542,
  cpc: 2.42,
  cpa: 50.0,
  description: "",
  created_at: new Date("2021-09-15T22:57:10.000Z"),
  pay_estimated: false,
  job_posted_at: "2024-04-30",
  occupations: [],
  feed_hash: "eyJzb3VyY2UiOiJhcHBjYXN0X2NwYSIsInJwYyI6MH0=",
  slug: "shift-supervisor-at-starbucks-coffee-company-7be93fee-c3f3-4118-855a-4f0fb5f9ab85",
  Employer: {
    id: "HMWxhQWUo83rQt",
    title: "Starbucks Coffee Company",
    logo_url: "images/starbucks.jpg",
    categories: ["coffee-shop"],
  },
  Store: {
    id: "00FKSIMDKXJJ-2jdif92me",
    title: "Paramus Park Mall",
    employer_id: "starbucks",
    exact_location: true,
  },
}

export const testUser: User = {
  id: "123",
  email: "bob@example.com",
  phone_number: "1234567890",
  first_name: "Bob",
  last_name: "Smith",
  created_at: new Date(),
  address: "577 Broadway, New York, NY",
  // @ts-expect-error Prisma does not like the Decimal type being overridden
  address_lat: 40.7246343,
  // @ts-expect-error Prisma does not like the Decimal type being overridden
  address_lng: -73.9976263,
  firebase_uid: null,
  picture: null,
  timezone: "America/New_York",
  consent_to_marketing: true,
}

export const testSession = {
  id: "501460ee-f9c9-48a6-8469-41b59c6408cc",
  userId: null,
  expiresAt: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  createdAt: new Date(),
  ipAddress: "127.0.0.1",
  // @ts-expect-error Prisma does not like the Decimal type being overridden
  geoipLat: 40.7246343 as Decimal,
  // @ts-expect-error Prisma does not like the Decimal type being overridden
  geoipLng: -73.9976263 as Decimal,
  trackingIds: { workmapsDeviceId: "501460ee-f9c9-48a6-8469-41b59c6408cc" },
  data: {},
  authMethod: null,
  address: "140 broadway, new york, new york",
  // @ts-expect-error Prisma does not like the Decimal type being overridden
  addressLat: 40.7246343 as Decimal,
  // @ts-expect-error Prisma does not like the Decimal type being overridden
  addressLng: -73.9976263 as Decimal,
} satisfies Session

export const testSessionWithUser = { ...testSession, userId: testUser.id }

export const trendingCategories = [
  { title: "Driving Jobs", icon: IconCar, slug: "driving" },
  { title: "Outdoor Jobs", icon: IconTrekking, slug: "outdoor" },
  { title: "Restaurant Jobs", icon: IconToolsKitchen2, slug: "restaurant" },
  { title: "Retail Jobs", icon: IconBuildingStore, slug: "retail" },
  { title: "Trade Jobs", icon: IconExchange, slug: "trade" },
  { title: "Warehouse Jobs", icon: IconBuildingWarehouse, slug: "warehouse" },
]
